@import "./asset/scss/mixin.scss";
@import "./asset/scss/variables.scss";

/* The sidebar menu */
.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: 0.5s;
}

.openbtn {
  @include background-font-color-mixin(#111, $font-white-color);
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  border: none;

  &:hover {
    background-color: #444;
  }
}

#main {
  transition: 0.5s;
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
}

/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */



/* 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */
/* @media (prefers-reduced-motion: no-preference) {
  .loading-logo {
    animation: loading-logo-spin infinite 2s linear;
  }
}

@keyframes loading-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */