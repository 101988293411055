.home {
    h1 {
        font-size: 40px;
        font-weight: 200;
        display: block;
        scroll-margin: 72px;
        margin: 24px 0 16px;
        animation: slideInFromLeft 0.5s;
    }

    .description {
        max-width: 900px;
        margin-bottom: 0;
        animation: slideInFromLeft 0.5s;
        animation-delay: 0.1s;
        opacity: 0;

        &.show {
            opacity: 1;
        }
    }

    .p-button {
        animation: slideInFromLeft 0.5s;
        animation-delay: 0.3s;
        opacity: 0;

        &.show {
            opacity: 1;
        }
    }

    .mp-section {
        .column {
            padding: 64px 32px;
        }

        .description {
            font-size: 18px;
            line-height: 28px;

            &:not(:last-child) {
                margin-bottom: 32px;
            }
        }
    }

    .main-hero {
        overflow: hidden;
        position: relative;
        height: 540px;

        &::before {
            content: "";
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: .2;
            background: url("../../asset/Icons/hero-bg-pattern.png") bottom -865px right no-repeat;
        }



        .hero-left {
            // &>* {
            //     transform: translateX(-50px);
            //     opacity: 0;
            // }

            .description {
                font-size: 22px;
                line-height: 32px;

                @media (max-width: 1500px) {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }

        .column {
            position: relative;
            margin: 0;
            z-index: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .hero-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @media (max-width: 991px) {
                display: none;
            }

            * {
                position: absolute;
                background-repeat: no-repeat;
            }

            .hero-bg-layer-1 {
                background-size: contain;
                width: 513px;
                height: 310px;
                right: 100px;
                bottom: 90px;
                animation: slideInFromRight 0.2s;
                animation-delay: 0.8s;
                opacity: 0;

                &.show {
                    opacity: 1;
                }

                @media (max-width: 1650px) {
                    right: 32px;
                }
            }

            .hero-bg-layer-2 {
                width: 225px;
                height: 375px;
                background-size: contain;
                right: 535px;
                bottom: -100px;
                animation: slideInFromBottom 0.2s;
                animation-delay: 1.1s;
                opacity: 0;

                &.show {
                    opacity: 1;
                }

                @media (max-width: 1650px) {
                    right: 467px;
                }
            }

            // .hero-bg-layer-3 {
            //     width: 80px;
            //     height: 80px;
            //     background-image: url("../../asset/Icons/sw_logo.svg");
            //     background-size: contain;
            //     right: 150px;
            //     top: 102px;
            //     animation: slideInFromTop 0.2s;
            //     animation-delay: 1.3s;
            //     opacity: 0;

            //     &.show {
            //         opacity: 1;
            //     }

            //     @media (max-width: 1650px) {
            //         right: 83px;
            //     }
            // }
        }

        .hero-left {
            padding: 0;
            margin-bottom: 0;
            max-width: 580px;

            @media (min-width: 1250px) and (max-width: 1500px) {
                max-width: 470px;
            }
        }

        .awards-links {
            z-index: 1;
            position: absolute;
            top: 24px;
            right: 32px;
            border-radius: 3px;
            padding: 8px;
            display: flex;
            background: #fff;
            animation: slideInFromRight 0.3s;
            animation-delay: 0.5s;
            opacity: 0;

            &.show {
                opacity: 1;
            }

            div {
                display: block;
                font-size: 0;
            }

            span {
                width: 1px;
                margin: 0 8px;
                background: #ebebeb;
            }
        }
    }

    .tooltip {
        @media (max-width: 991px) {
            display: none;
        }
    }
}

.dark {
    .main-hero {
        background: linear-gradient(45deg, #00007e 0, #0027a2 100%);

        .hero-left {
            .description {
                color: #f2f2f2;
            }
        }

        .column {
            color: #f2f2f2;
        }

        .hero-bg {
            .hero-bg-layer-1 {
                background-image: url("../../asset/Icons/main-hero-laptop-dark.png");
            }

            .hero-bg-layer-2 {
                background-image: url("../../asset/Icons/main-hero-hand-dark.png");

            }

        }
    }
}

.light {
    .main-hero {
        background: linear-gradient(45deg, #9ccbfd 0, #5491e4 100%);

        .hero-left {
            .description {
                color: #242424;
            }
        }

        .column {
            color: #242424;
        }

        .hero-bg {
            .hero-bg-layer-1 {
                background-image: url("../../asset/Icons/main-hero-laptop-light.png");
            }

            .hero-bg-layer-2 {
                background-image: url("../../asset/Icons/main-hero-hand-light.png");

            }
        }
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-50px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(50px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromBottom {
    from {
        transform: translateY(50px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInFromTop {
    from {
        transform: translateY(-50px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}