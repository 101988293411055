.supplierViewMore {
    .static{display: flex;
        .StaticInfo {width: calc(100% - 100px);
            .dataInfo {margin-bottom: 15px;
                .dataLabel {color: #888; margin-bottom: 5px;}
                .info {
                    .mailID{margin-top: 5px;}
                }
            }
        }
        .actionInfo{width: 100px;}
    }
}

#addnewUser-dialog{
    input[type=radio] {display: none;}
}
