@import "../../asset/scss/variables.scss";

// .user-stats-graph {
//     margin-bottom: 10px;
//     margin-top: 10px;
// }

.dark,
.light {
  .UserStats {
    .customRow {
      flex: 0;
      margin: 0 -8px;

      .data-box.card {
        max-width: calc(100% / 4 - 16px);
        flex-basis: calc(100% / 4 - 16px);
        border-right: 1px solid #767676;
        border-radius: 0;

        &:hover {
          border-color: #767676;
        }

        &:last-child {
          border-right: 1px solid #767676;
        }

        @media screen and (max-width: 991px) {
          max-width: calc(100% / 3 - 16px);
          flex-basis: calc(100% / 3 - 16px);
        }

        @media screen and (max-width: 756px) {
          max-width: calc(100% / 2 - 16px);
          flex-basis: calc(100% / 2 - 16px);
        }
      }
    }
  }
}