.SafetyMetrics {
    .customRow {display: flex; flex-flow: wrap;
        .card {
            width: calc(20% - 16px);
            padding: 8px;
            cursor: auto;

            @media screen and (min-width: 1600px) {
                width: calc(100%/7 - 16px);
            }

            @media screen and (min-width: 1990px) {
                width: calc(100%/9 - 16px);
            }

            div {
                height: auto;
            }

            .data-title {
                font-size: 14px;
            }

            .data-value {
                font-size: 18px;
            }

            .data-details {
                font-size: 11px;
            }
        }
    }
}