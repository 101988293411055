@import "../../asset/scss/variables.scss";

// .product {
//   font-size: 14px;
//   font-family: $font-ericssonHilda-bold;
//   color: $font-white-color !important;
//   line-height: 2.5em;
// }

.nav-dropdown-dark-username {
  a {
    color: #d7d7d7 !important;

    &:hover {
      color: $font-white-color !important;
    }
  }
}

.profile-icon-header {
  /* line-height: 0.1; */
  align-items: center;
}

.image-icon1 {
  margin: 10px;
}

.sysbar .items-container .item .icon-app-launcher {
  font-size: 25px !important;
}