div#root {
    height: 100%;
}

.signin .footer {
    position: static !important;
    background-color: transparent !important;
    @media only screen and (max-width: 991px) {
        width: 100% !important;
    }
}
body{
    &.light{
        .signin {background: #ebebeb !important; color: #242424 !important;
            .footer{color: #242424 !important;opacity: 0.8;}
        }
    }
   
    .signin {
        .waiting{
            .message{font-size: 15px !important; letter-spacing: 0.22px; font-weight: 400 !important;}
        }
        .watermark {padding: 45px 0 24px 110px !important;
            @media only screen and (max-width: 991px) {
                width: 90%; flex-direction: column; align-self: center; padding: 64px 0 85px 0 !important;  
            }
            .logoDiv {display: flex; flex-flow: column; align-items: center; width: 90px;            
                .siteLogo{width: 77px;
                    @media only screen and (max-width: 991px) {width: 70px;}
                }
                .product {font-size: 15px !important; width: 100%; padding: 7px 0 0 0 !important; letter-spacing: 0.22px; display: flex; justify-content: center;}
            }          
        }
    }
}