.tile.p-0 {
  padding: 0 !important;
}
.tile .top .right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .action {
    margin-right: 10px;
  }
  z-index: 0;
}
