@import "../../asset/scss/variables.scss";

.data-box {
  background-color: $background-color;
  // line-height: 3.5em;
  display: inline-block;
  border: 10px solid #181818;
  margin-top: 10px;
  text-align: center;

  /* z-index: 1; */
  div {
    padding: 0px;
    height: 55px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.data-value {
  margin: 10px;
  font-size: 40px;
  font-family: $font-ericssonHilda-bold;
}

.data-title {
  margin: 10px;
  font-size: 18px;
  // white-space: nowrap;
}