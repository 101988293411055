.multiSelectCheckBox {
  position: relative;

  .multiSelectDropDown.rmsc {
    width: 100%;
    --rmsc-main: #8e45b0 !important;
    --rmsc-hover: #8b8b8b !important;
    --rmsc-selected: #0077db !important;
    --rmsc-border: #767676 !important;
    --rmsc-gray: #f2f2f2 !important;
    --rmsc-bg: #181818 !important;
    --rmsc-radius: 0 !important;
    .item-renderer{align-items: center;}
    .options {
      max-height: 150px;
    }

    .dropdown-container {
      border-radius: 0;
      height: 32px;

      .dropdown-heading {
        height: 26px;
        padding-top: 3px !important;

        .clear-selected-button {
          display: none;
        }

        .dropdown-heading-value {
          span {
            display: inline-block;
            font-family: "Ericsson Hilda", Helvetica, sans-serif;
            font-weight: 300;
            font-size: 14px;
            line-height: 1.5;
            color: var(--rmsc-hover);
            background: var(--rmsc-bg);
          }
        }
      }

      .dropdown-content {
        z-index: 2;
        border: 1px solid #767676;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        width: calc(100% + 2px);
        margin: 4px -1px 0 -1px;
        padding: 0;

        .search input {
          background: var(--rmsc-bg);
          color: var(--rmsc-gray);
        }
      }
    }

    .dropdown-container:focus-within {
      box-shadow: var(--rmsc-main) 0 0 0 0;
    }
  }
}

.dark{
  .multiSelectCheckBox{
    .multiSelectDropDown.rmsc{
      .options{
        label{margin-bottom: 0;}
        li{padding-bottom: 0;
          label{margin-bottom: 0;}
          &.no-options{padding-bottom: 10px; color: #fff;}
        }
      }
      .dropdown-container{
        .dropdown-content{
          .search{
            input{color: #fff;}
          } 
        } 
      } 
    }
  }
  .rmsc .item-renderer input { width: 16px !important; height: 16px; border: 1px solid #fff; opacity: 1; -webkit-appearance: auto;} 
}

.light{
  .multiSelectCheckBox{
    .multiSelectDropDown.rmsc{--rmsc-bg: #fff !important;
      .options{
        label{margin-bottom: 0;
          &.selected{color: #fff;
            input{border: 1px solid #fff;}
          }
        }
        li{padding-bottom: 0;
          label{margin-bottom: 0;}
          &.no-options{padding-bottom: 10px; color: #242424;}
        }
      }
      .dropdown-container{
        .dropdown-content{
          .search{
            input{color: #242424;}
          } 
        } 
      } 
    }
  }
  .rmsc .item-renderer input {border: 1px solid #181818; width: 16px !important; height: 16px; opacity: 1; -webkit-appearance: auto; } 
}