// colors variable
$black-background:#000;
$font-white-color:#fff;
$background-scroll-color: #f1f1f1;
$background-color: #1f1f1f;
$button-react-datepicker:#0d6efd;
$h4-color: #ff8c0a;
$page-item-disabled-color: #6c757d;

//font
$font-ericssonHilda-regular: "EricssonHilda-Regular",
Helvetica,
Arial,
sans-serif;

$font-ericssonHilda-bold: "EricssonHilda-Bold",
Helvetica,
Arial,
sans-serif;