.table {
  .cell-split {
    img {
      display: block;
      width: 50px !important;
      height: 50px !important;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
