.custom-modal-body {
  display: flex;
  flex-direction: column;
}
/* @media (min-width: 576px) */
/* .custom-model .modal-dialog {
  --bs-modal-width: 350px;
} */

.buttom-alignment_1 {
  display: flex;
  justify-content: space-between;
}

.buttom-alignment_2 {
  display: flex;
}
