.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  line-height: normal;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .footerLink {
    margin: 0 5px;
    &:first-child{margin-left: 0;}
  }
}

.dark {
  .footer {
    .footerLink {
      color: #fff !important;
    }
  }
}

.light {
  .footer {
    .footerLink {
      color: #242424 !important;
    }
  }
}



.nav-content {
  padding: 10px 0;
}