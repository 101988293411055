.TasksAssociatedHazardsMitigation {

.content{
    @media (max-width: 991px) {
        overflow-x: auto;
        width: 100%;
    }
}

    .form-element {
        margin-bottom: 20px;

        &.inputContainer {
            display: flex;
            flex-wrap: wrap;

            .hintContainer {
                width: 50%;
                display: flex;
                flex-flow: column;
                padding: 10px 15px 0 40px;
                opacity: .6;

                @media (max-width: 760px) {
                    width: 100%;
                    order: 0;
                    padding: 10px 0 0 0;
                }

                .hintex {
                    margin-bottom: 5px;
                }

                .hint {
                    margin-bottom: 4px;
                }
            }
        }

        .inputText {
            width: 50%;
            max-width: 50%;


            @media (max-width: 760px) {
                width: 100%;
                max-width: 100%;
                order: 1;
            }

            input[type="text"] {
                width: calc(100% - 70px);
                max-width: calc(100% - 70px);
            }

            .w100 {
                width: 100%;
                text-align: center;
                margin: 15px 0;
            }

            .taskInput {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 15px;
                flex-wrap: wrap;

                input[type="text"] {
                    order: 1
                }

                .validation-msg {
                    order: 3;
                }

                button {
                    order: 2;
                }
            }
        }

        &.dropDiv {
            display: flex;

            .dropDownDiv {
                width: 35%;
                max-width: 35%;

                @media (max-width: 760px) {
                    width: 100%;
                    max-width: 100%;
                    margin-top: 20px;
                }

                .select {
                    width: 100%;
                    max-width: 100%;
                }

                label {
                    display: inline-block;
                    width: 70%;
                }
            }
        }

        .btn.clickable {
            text-align: left;
        }
    }

    .btnDiv {
        display: flex;
        justify-content: center;
        width: 100%;

        button {
            width: 200px;
        }
    }

    .alignRight {
        margin-left: auto;
    }

    .TaskAssocoatHazardData {
        margin-top: 20px;
        @media (max-width: 991px) {
            min-width: 1000px;
        }

        .customTable {
            width: 100%;

            tr {
                td {
                    padding: 0 0 16px 0;
                    width: calc(100% / 3);
                    border-bottom: 0;

                    .mitigation,
                    .hazard,
                    .references {
                        margin-bottom: 8px;
                    }
                }
            }
        }

        &.table {
            th {
                width: 20%;
            }
        }
    }
}