.background123 {
  /* height: 72vh; */
  background-color: #1f1f1f;
  /* width: 65vh; */
  justify-content: center;
  margin-top: 10px;
  width: 98%;
  height: 450px;
}

.graphText {
  color: rgb(255, 255, 255);
  flex-direction: column;
  flex: 10%;
  margin-top: 200px;
  font-size: 20px;
  backdrop-filter: blur(8px);
}
