.Main1 {
    min-height: 50vh;
    height: auto;
    background-color: #444;
    width: auto;
    background-size: cover;
    padding-top: 25px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    font-family: EricssonHilda-Regular;
}

.box-css {
    height: auto;
    width: (height/2);
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    z-index: 9999;
    padding: 20px;
    border-radius: 12px;
    font-family: EricssonHilda-Regular;

}

.Main12 {

    background-color: #444;

    font-family: EricssonHilda-Regular;
    text-align: center;
    color: white;
    margin-left: 0px;

}

.dropdown1 {
    width: 10vw;
    height: auto;
    text-align: center;
    border-radius: 12px;
    border: 0px;
    margin: 20px;
    padding: 6px;
    font-family: EricssonHilda-Regular;
}



.wrapper {
    text-align: center;
    margin-top: 45px;
    margin-right: 155px;
    width: auto;
    height: auto;
    font-family: EricssonHilda-Regular;
}

.button1 {

    position: absolute;

    justify-content: center;
    width: auto;
    height: auto;
    background-color: green;
    color: white;
    margin-left: 25px;
    border-radius: 1px;
    font-family: EricssonHilda-Regular;
}

.form-group1 {
    margin-top: 15px;
    margin-right: 35px;
    font-family: EricssonHilda-Regular;
}

.heading1 {
    color: white;
    font-family: EricssonHilda-Regular;
}

.Sucessmessage {
    text-align: center;
    font-size: 17px;
    font-family: EricssonHilda-Regular;
}

.SuccessIncident {
    text-align: center;
    font-family: EricssonHilda-Regular;
}

.SuccessID {
    text-align: center;
    font-family: EricssonHilda-Regular;
}

.headingname {
    font-family: EricssonHilda-Regular;
}