@import "../../asset/scss/variables.scss";

.sidebar {
  margin-top: 40.8px;
  padding-top: 2vh;
  background-color: $background-color;
  /* min-height: 100vh;*/
  height: auto;
  /* height: 125vh; */
  padding-right: inherit;
  padding-left: inherit;
}

.tab-item {
  margin-top: 5px;
  margin-bottom: 5px;
}

.tab-tile {
  border-radius: 0 !important;
  color: $font-white-color !important;
  height: 50px;
  line-height: 2.5em;
  padding-left: 2.5em;
  text-decoration: none;

  &:hover {
    background: #383838;
    color: $font-white-color;
  }
}

.tab-content-container {
  background-color: #181818;
  margin-top: 38.8px;
  margin-left: auto;
  margin-bottom: -1px;
  min-height: 100vh;
  min-width: 100vh;
  padding: 1px;
}

.tab-content {
  color: $font-white-color;
}

.sidebar-row {
  width: 100%;
  min-height: 800px;
  height: auto;
  margin: 0 0 38px 0;
  // overflow: auto;
}

/* @media screen and (max-width: 700px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }

    .sidebar a {
        float: left;
    }

    div.content {
        margin-left: 0;
    }
} */

/* @media screen and (max-width: 400px) {
    .sidebar a {
        text-align: center;
        float: none;
    }
} */

.anchor1:hover {
  position: fixed;
  background: #383838 !important;
  width: 16.7vw;
  height: 7vh;
}

.sidebar-icons {
  margin-right: 5px !important;
}
