//.css-13cymwt-control
.dark {
  .singleSelect {
    .react-select__menu {
      border-radius: 0px !important;
      background-color: #181818 !important;
      margin-top: 3px;
      border: 1px solid #767676 !important;
      font-family: "Ericsson Hilda", Helvetica, sans-serif;
      padding: 0 !important;
    }
    .react-select__menu .react-select__option {
      background-color: #181818 !important;
      // padding: 8px !important;
      margin-top: -4px !important;
      margin-bottom: -4px !important;
    }
    .react-select__menu .react-select__option:hover {
      background-color: #8b8b8b !important;
    }
    .react-select__menu .react-select__option--is-selected:hover {
      background-color: #0077db !important;
    }
    .react-select__menu .react-select__option--is-selected {
      background-color: #0077db !important;
    }
    .react-select__control--is-focused {
      border: 1px solid #8e45b0 !important;
      box-shadow: none;
    }
    // .react-select__control--menu-is-open {

    // }
    .react-select__control {
      box-shadow: none;
      border-color: #767676;
      //border: 1px solid;
      min-height: 32px !important;
      height: 32px;
      //box-shadow: 0 0 0 1px #8e45b0 !important;
      border-radius: 0px !important;
      background-color: #181818 !important;

      //   .react-select__control--menu-is-open {
      //     border-radius: 0px !important;

      //   }

      .react-select__value-container {
        height: 30px;
        padding: 0 6px;
        .react-select__single-value {
          color: #f2f2f2;
          font-family: "Ericsson Hilda", Helvetica, sans-serif;
        }
        .react-select__input-container {
          .react-select__Input {
            margin: 0px;
            color: #8b8b8b;
          }
        }
        // .react-select__input-container:hover {
        //   background-color: #8b8b8b !important;
        // }
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }
        .react-select__indicator {
          svg {
            height: 24px;
            width: 24px;
          }
          height: 30px;
          color: #f2f2f2;
          margin-top: -10px;
        }
        // .react-select__indicatorContainer {
        //   svg {
        //     height: 24px;
        //     width: 24px;
        //   }
        //   height: 30px;
        //   color: #f2f2f2;
        //   margin-top: -10px;
        // }
      }

      // .css-tj5bde-Svg {
      //   margin-top: -5px;
      // }
    }
  }
}

.light {
  .singleSelect {
    .react-select__menu {
      border-radius: 0px !important;
      // background-color: #f2f2f2;
      margin-top: 3px;
      border: 1px solid #767676;
    }
    .react-select__menu:hover {
      color: grey;
    }
    .react-select__menu .react-select__option {
      background-color: white !important;
      color: #181818 !important;
      margin-top: -4px !important;
      margin-bottom: -4px !important;
    }
    .react-select__menu .react-select__option:hover {
      background-color: #8b8b8b !important;
      color: #f2f2f2 !important;
    }
    .react-select__menu .react-select__option--is-selected {
      background-color: #0077db !important;
      color: #f2f2f2 !important;
    }
    .react-select__menu .react-select__option--is-selected:hover {
      background-color: #0077db !important;
      color: #f2f2f2 !important;
    }
    .react-select__menu
      .react-select__option:not(.react-select__option--is-selected):hover {
      background-color: #8b8b8b !important;
      color: #181818 !important;
    }

    .react-select__control--is-focused {
      border: 1px solid #8e45b0 !important;
      box-shadow: none;
    }
    // .react-select__control--menu-is-open {

    // }
    .react-select__control {
      box-shadow: none;
      border-color: #767676;
      //border: 1px solid;
      min-height: 32px !important;
      height: 32px;
      //box-shadow: 0 0 0 1px #8e45b0 !important;
      border-radius: 0px !important;
      //background-color: #181818 !important;

      //   .react-select__control--menu-is-open {
      //     border-radius: 0px !important;

      //   }

      .react-select__value-container {
        height: 30px;
        padding: 0 6px;
        .react-select__single-value {
          //   color: #f2f2f2;
          font-family: "Ericsson Hilda", Helvetica, sans-serif;
        }
        .react-select__input-container {
          .react-select__Input {
            margin: 0px;
            color: #8b8b8b;
          }
        }
      }

      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }
        .react-select__indicator {
          svg {
            height: 24px;
            width: 24px;
          }
          height: 30px;
          color: #f2f2f2;
          margin-top: -10px;
        }
        // .react-select__indicatorContainer {
        //   svg {
        //     height: 24px;
        //     width: 24px;
        //   }
        //   height: 30px;
        //   color: #f2f2f2;
        //   margin-top: -10px;
        // }
      }

      // .css-tj5bde-Svg {
      //   margin-top: -5px;
      // }
    }
  }
}
