.dark,
.light {
  .tree li>a:focus {
    outline: none !important;
  }
}



.star-icon {
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: auto;
  width: 17px;
 
}

.pin-icon {
  width: 90%;
  height: auto;
  fill: #ffffff;
  stroke: none;
align-self: flex-end;
}

.dark .pin-icon {
  filter: brightness(0) invert(1);
}

.navlink-item {
  display: flex;
  align-items: center;
  position: relative;

  /* Add position relative to create a stacking context */
}

.navlink-item .item {
  white-space: nowrap;
  overflow: visible;
  flex: 1;
  // width: 207px;
}

.star-icon-container{
  display: flex;
  align-items: center;
  margin-left: auto;
}

.star-icon-container .star-icon{
  margin-left: 8px;
}



.light .tree .active:not(.opened),
.light .tree .active:not(.title) {
  background: #1174e6;
}

.light .tree .active:not(.opened),
.light .tree .active:not(.title) {
  background: #1174e6;
}