.custom-table-header1 {
  text-align: left;
  vertical-align: text-top;
  width: 13%;
}

.custom-table-body1  {
  width: 13%;
  font-size: small;
  inline-size: min-content;
  text-align: left !important;
  vertical-align: text-top !important;
  word-break: break-all;
}

.custom-table-header2 {
  text-align: left !important;
  vertical-align: text-top !important;
  width: 20%;
}

.custom-table-body2 {
  width: 20%;
  font-size: small;
  inline-size: min-content;
  text-align: left !important;
  vertical-align: text-top !important;
}

.custom-table-header3 {
  text-align: center !important;
  vertical-align: text-top !important;
  width: 10%;
}

.custom-table-body3 {
  width: 10%;
  font-size: small;
  inline-size: min-content;
  text-align: center !important;
  vertical-align: text-top !important;
}

.table tbody tr .statusHandler {
  width: 70px;
}

.dark .btn.color-red {
  color: #dc2d37 !important;
}

.dark .btn.color-green {
  color: #288964 !important;
}

.list-group {padding-left: 0; max-height: 150px; overflow-y: auto;
  .list-group-item {list-style-type: none;padding: 7px 10px; border: 1px solid rgba(0, 0, 0, 0.4);border-bottom: 0;
    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      
    }
    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
    .link{color: #fff; text-decoration: underline;}
  }
}