.dark .task-group {
  padding-left: 0;
  max-height: 300px;
  overflow-y: auto;

  .task-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    background-color: #181818;
    border: 1px solid #767676;
    padding: 5px;
  }
}

.light .task-group {
  padding-left: 0;
  max-height: 300px;
  overflow-y: auto;

  .task-group-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    background-color: #fff;
    border: 1px solid #878787;
    padding: 5px;
  }
}
